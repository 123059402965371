import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { colors } from '../../const'
import { IRuneSupply } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getRuneSupplyConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_runesupply', isRefreshing, timeframe)) as IRuneSupply[]
	const series1 = chartData.map((element: IRuneSupply) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_LENDING_BURN }
	})
	const series2 = chartData.map((element: IRuneSupply) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_KILLSWITCH_BURN }
	})
	const series3 = chartData.map((element: IRuneSupply) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_ADR12_BURN }
	})
	const series4 = chartData.map((element: IRuneSupply) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_ADR17_BURN }
	})
	const series5 = chartData.map((element: IRuneSupply) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CURRENT_RUNE_SUPPLY }
	})
	const config: IChart[] = [
		{
			data: series5,
			strokeWidth: 2,
			name: 'ᚱune Supply',
			type: 'line',
			seriesGroup: '[rune] ',
			color: 'white',
			unitSymbol: 'ᚱ'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'killswitch',
			type: 'bars',
			seriesGroup: '[rune]',
			color: 'orange',
			unitSymbol: 'ᚱ',
			barGroup: 'rune'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'adr12',
			type: 'bars',
			seriesGroup: '[rune]',
			color: 'green',
			unitSymbol: 'ᚱ',
			barGroup: 'rune'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'lending',
			type: 'bars',
			seriesGroup: '[rune]',
			color: `${colors[1]}`,
			unitSymbol: 'ᚱ',
			barGroup: 'rune'
		},
		{
			data: series4,
			strokeWidth: 2,
			name: 'adr17',
			type: 'bars',
			seriesGroup: '[rune]',
			color: 'red',
			unitSymbol: 'ᚱ',
			barGroup: 'rune'
		}
	]

	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const RuneSupply = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getRuneSupplyConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="RUNE Supply Changes"
				chartDesc="Changes in the current RUNE supply."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="2b7a875e-cce0-49c2-ad6c-87f2a7c6fc16"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default RuneSupply
